import { Layout, Row, Col } from "antd";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className='just'>
        <Col xs={24}>
          <div className='copyright'>© {currentYear}, V1HUB</div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;

import { useQuery } from "react-query";
import { getProfileAPI } from "../services/auth";

export const fetchProfile = "fetchProfile";
export const useFetchProfile = () => {
  const { isLoading, data, refetch } = useQuery([fetchProfile], {
    queryFn: () => getProfileAPI(),
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 1,
  });

  return {
    handleFetchProfile: refetch,
    isLoading,
    profile: data?.data?.data || undefined,
  };
};

import { LogoutOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  List,
  MenuProps,
  Row,
} from "antd";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ReactComponent as Clockicon } from "../../assets/image/Clockicon.svg";
import { ReactComponent as Credit } from "../../assets/image/Credit.svg";
import { ReactComponent as ProfileIcon } from "../../assets/image/ProfileIcon.svg";
import { ReactComponent as Toggler } from "../../assets/image/Toggler.svg";
import logo from "../../assets/image/V1_LOGO_DARK.svg";
import { ReactComponent as Wifi } from "../../assets/image/Wifi.svg";
import useAuth from "../../hooks/useAuth";
import { fetchProfile, useFetchProfile } from "../../hooks/useFetchProfile";
import { useLogout } from "../../pages/Login/hooks/useLogout";
import { ListRoutes } from "../../routes";
import { Member } from "../../services/auth/type";

const data = [
  {
    title: "New message from Sophie",
    description: <>{Clockicon} 2 days ago</>,

    avatar: (
      <Avatar shape='square'>
        <Wifi />
      </Avatar>
    ),
  },
  {
    title: "New album by Travis Scott",
    description: <>{Clockicon} 2 days ago</>,

    avatar: (
      <Avatar shape='square'>
        <Wifi />
      </Avatar>
    ),
  },
  {
    title: "Payment completed",
    description: <>{Clockicon} 2 days ago</>,
    avatar: (
      <Avatar shape='square'>
        <Credit />
      </Avatar>
    ),
  },
];

const menu = (
  <List
    min-width='100%'
    className='header-notifications-dropdown '
    itemLayout='horizontal'
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape='square' src={item.avatar} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    )}
  />
);

const Header = ({
  name,
  subName,
  onPress,
}: {
  name: string;
  subName: string;
  onPress: () => void;
}) => {
  const queryClient = useQueryClient();
  const profileData: any = queryClient.getQueryState(fetchProfile);
  const profile = profileData?.data?.data?.data as Member;
  const { handleLogout } = useLogout();
  const { accessToken } = useAuth();
  const { handleFetchProfile, isLoading } = useFetchProfile();

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    if (accessToken) {
      handleFetchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className='btn-option'
          onClick={() => handleLogout.mutate({ value: accessToken || "" })}>
          <LogoutOutlined style={{ fontSize: "16px" }} />
          Logout
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={12} md={6}>
        {/* <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to='/'>Pages</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name.replace("/", "")}</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className='header-brand'>
          <div className='brand'>
            <img src={logo} alt='' />
          </div>
        </div>
      </Col>
      <Col span={12} md={18} className='header-control'>
        {/* <Badge size="small" count={4}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              href="#pablo"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Bell />
            </a>
          </Dropdown>
        </Badge> */}
        <Button
          type='link'
          className='sidebar-toggler'
          onClick={() => onPress()}>
          <Toggler />
        </Button>
        {accessToken ? (
          <Dropdown
            menu={{ items }}
            placement='bottom'
            arrow={{ pointAtCenter: true }}
            disabled={isLoading}>
            <div className='btn-sign-in'>
              {profile?.avatar ? (
                <Avatar src={profile.avatar} size={16} />
              ) : (
                <ProfileIcon />
              )}
              {profile?.name}
            </div>
          </Dropdown>
        ) : (
          <Link to={ListRoutes.LOGIN} className='btn-sign-in'>
            <ProfileIcon />
            <span>Sign in</span>
          </Link>
        )}
        <Input
          className='header-search d-mb-none'
          placeholder='Type here...'
          prefix={<SearchOutlined />}
        />
      </Col>
    </Row>
  );
};

export default Header;

import { useMutation } from "react-query";
import { logoutAPI } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { ListRoutes } from "../../../routes";
import { v1hubAuthToken } from "../../../hooks/useAuth";

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = useMutation(
    (body: { value: string }) => {
      return logoutAPI(body);
    },
    {
      onSuccess: () => {
        localStorage.removeItem(v1hubAuthToken);
        navigate(ListRoutes.LOGIN);
      },
      onError: () => {
        localStorage.removeItem(v1hubAuthToken);
        navigate(ListRoutes.LOGIN);
      },
    }
  );

  return { handleLogout };
};

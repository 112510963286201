import { axiosInceptors } from "../axiosAdmin";
import { ResponseServerAPI } from "../server.type";
import { LoginForm, Member, NewAuthentication, RefreshTokenBody } from "./type";

const WEB_APP_API = process.env.REACT_APP_WEB_APP;
const IDENTITY_API = process.env.REACT_APP_IDENTITY_API;

export const refreshTokenAPI = ({ value, useId }: RefreshTokenBody) => {
  return axiosInceptors.get(
    IDENTITY_API + "api/v1/authentication/jwt/refresh-token",
    {
      params: { value, useId },
    }
  );
};

export const logoutAPI = ({
  value,
}: Partial<Pick<RefreshTokenBody, "value">>) => {
  if (!value) {
    throw new Error("Value must be required");
  }
  return axiosInceptors.get(
    IDENTITY_API + "api/v1/authentication/jwt/refresh-token/revoke",
    {
      params: { value },
    }
  );
};

export const loginAPI = (data: LoginForm) => {
  return axiosInceptors.post<ResponseServerAPI<NewAuthentication>>(
    IDENTITY_API + "api/v1/authentication/jwt/admin/login",
    { ...data }
  );
};

export const getProfileAPI = () => {
  return axiosInceptors.get<ResponseServerAPI<Member>>(
    WEB_APP_API + "Member/profile"
  );
};

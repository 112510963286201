import { NewAuthentication } from "../services/auth/type";

export const v1hubAuthToken = "v1hub_admin";

export default function useAuth() {
  const storage = localStorage.getItem(v1hubAuthToken);
  const storageParse: NewAuthentication | undefined =
    storage || storage === "undefined" ? JSON.parse(storage) : undefined;

  return {
    accessToken: storageParse ? storageParse?.accessToken : "",
  };
}

import axios, { AxiosRequestConfig } from "axios";
import { EnvConfig } from "../helper/config";
import { refreshTokenAPI } from "./auth";
import { saveAppLocalStorage } from "../helper";
import { Member, NewAuthentication, RefreshTokenBody } from "./auth/type";
import { v1hubAuthToken } from "../hooks/useAuth";

const v1hub = localStorage.getItem(v1hubAuthToken);
const v1hubParse: NewAuthentication =
  v1hub && v1hub !== "undefined" ? JSON.parse(v1hub) : undefined;
const tokenString = v1hubParse ? v1hubParse?.accessToken : undefined;

console.log(tokenString);

const config: AxiosRequestConfig = {
  baseURL: EnvConfig.REACT_APP_ADMIN_APP,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: tokenString ? `Bearer ${tokenString}` : undefined,
  },
};

export const axiosInceptors = axios.create(config);

axiosInceptors.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data?.detail === "token is expired") {
      const localStorageItem = localStorage.getItem(v1hubAuthToken);
      const parse = localStorageItem ? JSON.parse(localStorageItem) : "";
      if (parse) {
        const {
          member,
          refreshToken,
        }: { member?: Member; refreshToken?: string } = parse;
        if (member?.userId && refreshToken) {
          refreshTokenAPI({
            useId: member.userId,
            value: refreshToken,
          } as RefreshTokenBody)
            .then(({ data }) => {
              saveAppLocalStorage(data.data);
              window.location.reload();
            })
            .catch((error) => {
              localStorage.removeItem(v1hubAuthToken);
              window.location.reload();
              console.error("Refresh token failed: ", error);
            });
        } else {
          localStorage.removeItem(v1hubAuthToken);
          window.location.reload();
        }
      }
    }
    return error;
  }
);

import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { ListRoutes } from "../../routes";
import useAuth from "../../hooks/useAuth";

const { Header: AntHeader, Content, Sider } = Layout;

const sidenavColor = "#1890ff";
const sidenavType = "transparent";

const Main = () => {
  const [visible, setVisible] = useState(false);
  const openDrawer = () => setVisible(!visible);
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  let { pathname } = useLocation();
  pathname = pathname.replace(ListRoutes.LOGIN, "");

  useEffect(() => {
    if (!accessToken) {
      navigate(ListRoutes.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <Layout className={`layout-dashboard `}>
      <Drawer
        title={false}
        placement={"right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={"right"}
        width={250}
        className={`drawer-sidebar`}>
        <Layout className={`layout-dashboard `}>
          <Sider
            trigger={null}
            width={250}
            theme='light'
            className={`sider-primary ant-layout-sider-primary active-route`}
            style={{ background: sidenavType }}>
            <Sidenav color={sidenavColor} onClose={() => setVisible(false)} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint='lg'
        collapsedWidth='0'
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        width={250}
        theme='light'
        className={`sider-primary ant-layout-sider-primary active-route`}
        style={{ background: sidenavType }}>
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout>
        <Affix>
          <AntHeader className='ant-header-fixed'>
            <Header onPress={openDrawer} name={pathname} subName={pathname} />
          </AntHeader>
        </Affix>
        <Content className='content-ant'>
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Main;
